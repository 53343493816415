import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"

const style = {
  display: 'block',
  padding: '4px 0',
  fontSize: '20px'
}

const Contributors = () => (
  <Layout>
    <SEO title="Contributors" />
    <h1>Contributors</h1>
    <hr/>
    <a style={style} href='https://twitter.com/VasilevskiAleks'>Aleksandar Vasilevski</a>
    <a style={style} href='https://twitter.com/bojan_vas'>Bojan Vasilevski</a>
    <a style={style} href='https://www.ivanagrbic.com'>Ivana Grbic</a>
    <hr/>
  </Layout>
)

export default Contributors